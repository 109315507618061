@import url('https://fonts.cdnfonts.com/css/outfit');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');
 @import url('https://fonts.cdnfonts.com/css/optima');

body {
  margin: 0%;
  padding: 0%;
  font-family: outfit;
  background-color: #F5F5F5;
}

a {
  color: inherit;
  text-decoration: none;
}

.carousel {
  position: relative;
  width: 100%;
  height: 300px;
}

.carousel-images {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.carousel-image {
  display: none;
  /* background-size: contain; */
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carousel-image.active {
  display: block;
  animation: fade 1s ease-in-out;
}

.carousel-dots {
  position: absolute;
  top: 100px;
  left: 95%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.carousel-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #BC172F;
  margin: 0 5px;
  cursor: pointer;
  transition: 0.5s all linear;
}

.carousel-dot.active {
  height: 30px;
  border-radius: 100px;
  background-color: #ead2d5;
}

.hidden_map {
  overflow: hidden !important;
}

.hide_scrollbar::-webkit-scrollbar {
  display: none;

}

.hide_scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.container .item {
  width: 100%;
  float: left;
  padding: 0 20px;
  background: #ece1e2;
  overflow: hidden;
  margin: 10px 0px;
  box-sizing: border-box;
  display: flex;
  border-radius: 10px;
}

.container .item-right,
.container .item-left {
  float: left;
  padding: 20px
}

.container .item-right {
  /* padding: 79px 50px; */
  margin-right: 20px;
  width: 25%;
  position: relative;
  /* height: 286px */
}

.container .item-right .up-border,
.container .item-right .down-border {
  padding: 14px 15px;
  background-color: #F5F5F5;
  border-radius: 50%;
  position: absolute
}

.container .item-right .up-border {
  top: -8px;
  right: -35px;
}

.container .item-right .down-border {
  bottom: -13px;
  right: -35px;
}

.container .item-right .num {
  font-size: 60px;
  text-align: center;
  color: #111
}

.container .item-right .day,
.container .item-left .event {
  color: #555;
  font-size: 20px;
  /* margin-bottom: 9px; */
}

.container .item-right .day {
  text-align: center;
  font-size: 25px;
}

.container .item-left {
  width: 71%;
  padding: 34px 0px 19px 46px;
  border-left: 3px dotted #999;
}

.container .item-left .title {
  color: #111;
  font-size: 34px;
  margin-bottom: 12px
}

.container .item-left .sce {
  margin-top: 5px;
  display: block
}

.container .item-left .sce .icon,
.container .item-left .sce p,
.container .item-left .loc .icon,
.container .item-left .loc p {
  float: left;
  word-spacing: 5px;
  letter-spacing: 1px;
  color: #888;
  margin-bottom: 10px;
}

.container .item-left .sce .icon,
.container .item-left .loc .icon {
  margin-right: 10px;
  font-size: 20px;
  color: #666
}

.container .item-left .loc {
  display: block
}

.fix {
  clear: both
}

.container .item .tickets,
.booked,
.cancel {
  color: #fff;
  padding: 6px 14px;
  float: right;
  margin-top: 10px;
  font-size: 18px;
  border: none;
  cursor: pointer
}

.container .item .tickets {
  background: #777
}

.linethrough {
  text-decoration: line-through
}

@media only screen and (max-width: 1150px) {
  .container .item {
    width: 100%;
    /* margin-right: 20px */
  }

}

@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.ql-editor {
  font-family: 'outfit' !important;
  padding: 0 !important;
  margin-top: 10px !important;
  font-weight: 300 !important;
}


.paystack-button {
  background-color: #bc172f;
  border: none;
  padding: 20px;
  font-family: outfit;
  font-weight: bold;
  width: 100%;
  color: #fff;
  margin-top: 20px;
  border-radius: 8px;
}

.paystack-button-disabled {
  background-color: #d7d7d7;
  cursor: not-allowed;
  padding: 20px;
  font-family: outfit;
  font-weight: bold;
  width: 100%;
  border: none;
  color: #9f9f9f;
  margin-top: 20px;
    border-radius: 8px;
}

.two-line-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}